<template>
  <v-card flat>
    <v-card-title class="primary--text text-bold">Events</v-card-title>
    <v-card-text>
      <v-row v-if="loading">
        <v-col cols="12" sm="3" v-for="item in 9" :key="item">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" sm="3" v-for="event in events" :key="event._id">
          <v-responsive aspect-ratio="1">
          <v-card class="ma-2" :to="{ name: 'visitor.event', params: { id: event.slug } }">
            <v-layout justify="end" v-if="event.status === 'cancelled'">
              <v-sheet
                class="mt-4"
                style='z-index:1;position:absolute;'
              >
                <v-chip label color="red" text-color="white" class="rounded-0">
                  CANCELLED
                </v-chip>
              </v-sheet>
            </v-layout>
            <v-img :aspect-ratio="16/9" :src="$getOrignalImageByWidth(event.image, event.imageUserId, event._id, 1200)"></v-img>

            <v-list-item two-line>
              <v-list-item-action class="mr-4 primary--text d-grid text-bold">
                <div class="text-center">{{ event.startsAt | day }}</div>
                <div>{{ event.startsAt | month }}</div>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ event.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ event.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          </v-responsive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';

export default {
  data() {
    return {
      events: [],
      loading: false,
    };
  },
  created() {
    this.manageUserActivity({event:'venue clicked ==>'+this.$route.params.id});
    this.getGenres();
    if (this.$route.query.utm_source) {
      localStorage.setItem('utm_source', JSON.stringify({ 
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
        utm_eventid: this.$route.params.id,
      }))
    }
  },
  methods: {
    async getGenres() {
      this.loading = true;
      const response = await PromoterEventService.getEventsByVenue(this.$route.params.id);
      if (response) {
        this.events = response;
      }
      this.loading = false;
    },
  },
};
</script>
